import { CustomBadge, RootContainer } from "@/components"
import "./QuoteDetailPage.scss"
import { useLazyGetInvoiceQuery } from "@/services/apiDigifabster/order"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom"
import { useEffect, useMemo } from "react"
import { useAppLoading } from "@/hooks/useLoading"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { format } from "date-fns"
import { useCurrency } from "@/hooks/useCurrency"
import QuoteItem from "./components/QuoteItem/QuoteItem"
import QuoteSummary from "./components/QuoteSummary/QuoteSummary"
import { BadgeType, Size } from "@/enums/common.enum"
import { BadgeColorQuoteStatus } from "../QuotesPage/components/QuotesListTable"
import { NotesIcon } from "@/assets"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { useGetAdditionalFileQuery } from "@/services/apiDigifabster/quote"
import { IAuthStore } from "@/store/auth"

function QuoteDetailPage() {
  const { invoiceId, invoice_hash } = useParams()
  const [getInvoiceDetail, { isFetching, isError, data }] =
    useLazyGetInvoiceQuery()
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const orderId = invoiceDetail?.order?.id
  const { data: fileData, isFetching: isFetchingFile } =
    useGetAdditionalFileQuery(
      { quoteId: String(invoiceDetail?.order?.id) },
      { skip: !orderId }
    )

  const { isAuthenciated } = useSelector<RootState, IAuthStore>(
    (state) => state.auth
  )
  const { pathname, search } = useLocation()
  const { currency } = useCurrency()

  const navigate = useNavigate()

  useEffect(() => {
    if (invoiceId && invoice_hash) {
      getInvoiceDetail({ id: invoiceId, hash: invoice_hash })
    }
  }, [])

  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  useAppLoading([isFetching, isFetchingFile])

  const product = invoiceDetail?.order?.line_items
  const order = invoiceDetail?.order
  const createdAt = order?.date
    ? format(new Date(order.date), "MM-dd-yyyy")
    : ""
  const additionalContact =
    order?.additional_billing_contacts?.length > 0
      ? order.additional_billing_contacts?.[0]
      : ""

  const hideBillingAddress = useMemo(() => {
    return (
      order?.billing_address?.company_name ||
      order?.billing_address?.street_address ||
      order?.billing_address?.apartment ||
      order?.billing_address?.city ||
      order?.billing_address?.state ||
      order?.billing_address?.country ||
      order?.billing_address?.postcode
    )
  }, [order?.billing_address])

  const handleBack = () => {
    if (pathname.includes("quotes")) {
      const params = new URLSearchParams(search)
      const page = params.get("page")
      const limit = params.get("limit")

      return navigate(`/quotes?page=${page}&limit=${limit}`)
    }
    if (pathname.includes("orders")) return navigate(`/orders/${order.id || 0}`)
    if (pathname.includes("checkout"))
      return navigate(`/new-quote/checkout/${order.id || 0}/submitted`)
  }

  if (!data || isFetching || isFetchingFile) return <></>

  return (
    <RootContainer
      onBack={isAuthenciated ? handleBack : undefined}
      headerText="Quote details"
    >
      <div className="quote">
        <div className="quote-left">
          <div className="quote-left-content">
            <div className="quote-left-content-detail">
              <div className="quote-left-content-detail-header">
                <div className="quote-left-content-detail-header-wrapper">
                  <div className="quote-left-content-detail-header-title">
                    <p>Quote #{invoiceDetail?.order?.company_order_id}</p>
                    <CustomBadge
                      content={order?.status_display}
                      type={BadgeType.TONAL}
                      size={Size.MEDIUM}
                      color={
                        BadgeColorQuoteStatus[
                          order.status as keyof typeof BadgeColorQuoteStatus
                        ]
                      }
                    />{" "}
                  </div>
                  <div className="quote-left-content-detail-header-status">
                    <p>Created {createdAt}</p>

                    <p>
                      <b>Estimated Manufacturing Time:</b>{" "}
                      {order?.delivery_time} Days
                    </p>
                  </div>
                </div>
              </div>
              <div className="quote-left-content-info">
                <div className="quote-left-content-info-top">
                  <div className="quote-left-content-info-top-contact">
                    <div className="quote-left-content-info-top-contact-wrapper">
                      <div className="quote-left-content-info-top-contact-header">
                        <p>CONTACT</p>
                      </div>
                      <p className="quote-left-content-info-top-contact-body">
                        <b>Name: </b>
                        {order?.buyers_full_name}
                      </p>
                      <p className="quote-left-content-info-top-contact-body">
                        <b>Phone: </b>
                        {order?.phone}
                      </p>
                      <p className="quote-left-content-info-top-contact-body">
                        <b>Additional contacts: </b>
                        {additionalContact || "-"}
                      </p>
                    </div>

                    <div className="quote-left-content-info-top-contact-wrapper">
                      <div className="quote-left-content-info-top-contact-header">
                        <p>SHIPPING ADDRESS</p>
                      </div>
                      {order?.shipping_address?.company_name && (
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.shipping_address?.company_name &&
                          order?.shipping_address?.company_name !== "blank"
                            ? order?.shipping_address?.company_name
                            : ""}
                        </p>
                      )}

                      {(order?.shipping_address?.street_address ||
                        order?.shipping_address?.apartment) && (
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.shipping_address?.street_address || ""}
                          {order?.shipping_address?.apartment &&
                            `, ${order?.shipping_address?.apartment || ""}`}
                        </p>
                      )}

                      {(order?.shipping_address?.city ||
                        order?.shipping_address?.state) && (
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.shipping_address?.city || ""}
                          {order?.shipping_address?.state &&
                            `, ${order?.shipping_address?.state || ""}`}
                        </p>
                      )}

                      {(order?.shipping_address?.country ||
                        order?.shipping_address?.postcode) && (
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.shipping_address?.country || ""}
                          {order?.shipping_address?.postcode &&
                            `, ${order?.shipping_address?.postcode || ""}`}
                        </p>
                      )}
                    </div>

                    <div className="quote-left-content-info-top-contact-wrapper-shipping-method">
                      <div className="quote-left-content-info-top-contact-header">
                        <p>SHIPPING METHOD</p>
                      </div>
                      <p>
                        {order?.delivery_title ||
                          order?.delivery_type_title ||
                          "-"}
                      </p>
                    </div>
                  </div>

                  <div className="quote-left-content-info-top-billing">
                    <div className="quote-left-content-info-top-contact-wrapper">
                      <div className="quote-left-content-info-top-billing-header">
                        <p>BILLING CONTACT</p>
                      </div>
                      <p className="quote-left-content-info-top-contact-body">
                        <b>Name: </b> {order?.billing_fullname || "-"}
                      </p>
                      <p className="quote-left-content-info-top-contact-body">
                        <b>Email: </b> {order?.billing_email || "-"}
                      </p>
                      <p className="quote-left-content-info-top-contact-body">
                        <b>Phone: </b> {order?.billing_phone || "-"}
                      </p>
                    </div>

                    {hideBillingAddress && (
                      <div className="quote-left-content-info-top-contact-wrapper">
                        <div className="quote-left-content-info-top-billing-header">
                          <p>BILLING ADDRESS</p>
                        </div>
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.billing_address?.company_name}
                        </p>
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.billing_address?.street_address}
                          {order?.billing_address?.apartment &&
                            `, ${order?.billing_address?.apartment}`}
                        </p>
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.billing_address?.city}
                          {order?.billing_address?.state &&
                            `, ${order?.billing_address?.state}`}
                        </p>
                        <p className="quote-left-content-info-top-contact-body">
                          {order?.billing_address?.country}
                          {order?.billing_address?.postcode &&
                            `, ${order?.billing_address?.postcode}`}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="quote-left-content-detail-product">
              <div className="quote-left-content-detail-product-header">
                <p>MODEL</p>
                <p>SPECIFICATIONS</p>
                <p>POST PRODUCTION</p>
                <p>QUANTITY</p>
                <p>TOTAL</p>
              </div>
              <div className="quote-left-content-detail-product-list">
                {product?.map((item) => (
                  <QuoteItem
                    currency={currency}
                    key={item.id}
                    item={item}
                  ></QuoteItem>
                ))}
              </div>
            </div>

            {order?.notes && (
              <div className="invoice-left-content-note">
                <div className="invoice-left-content-note-header">
                  <img src={NotesIcon} alt="" />
                  <p>NOTES</p>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${order?.notes}`
                  }}
                ></div>
              </div>
            )}
            {!!fileData?.length && (
              <div className="invoice-left-content-files">
                {fileData?.map((item: any) => (
                  <div
                    key={item.id}
                    className="invoice-left-content-files-item"
                  >
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="invoice-right">
          <QuoteSummary></QuoteSummary>
        </div>
      </div>
    </RootContainer>
  )
}

export default QuoteDetailPage
