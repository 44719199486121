import { useMemo, useState } from "react"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import "./ProductItem.scss"
import { Button, CustomCheckbox, NumberField } from "@/components"
import {
  LineItem,
  useDeletePurchaseMutation,
  useUpdateProductMutation
} from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { useSelector } from "react-redux"
import { ModelResponse } from "@/services/apiDigifabster/model"
import {
  BlockIcon,
  LoadingIcon,
  OrderReviewIcon,
  SuccessToastIcon,
  WarningDFMIcon
} from "@/assets"
import ViewerModal from "../ViewerModal/ViewerModal"
import { useAppLoading } from "@/hooks/useLoading"
import { priceDisplay } from "@/utils/functionHelper"
import {
  EModelAnalysisStatus,
  IModelAnalysis,
  IProductStore
} from "@/store/product"
import { useCurrency } from "@/hooks/useCurrency"
import Skeleton from "react-loading-skeleton"
import { calculateDeliveryDate } from "@/utils/timeFormatHelper"
import { calculatePerPartCost } from "@/utils/dataHelper"

interface ProductItemProps {
  isSelected: boolean
  onSelect: (selected: boolean) => void
  onEditItem: (id: number) => void
  item: LineItem
  isEditable?: boolean
  openDrawer?: (id: number) => void
}

export const buttonStatusClass = {
  [EModelAnalysisStatus.LOADING]: "button-loading",
  [EModelAnalysisStatus.SUCCESS]: "button-success",
  [EModelAnalysisStatus.WARNING]: "button-warning",
  [EModelAnalysisStatus.ERROR]: "button-error"
}

export const tagStatusClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "corner-bottom-right-bevel-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessToastIcon,
    className: "corner-bottom-right-bevel-success"
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "corner-bottom-right-bevel-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Manufacturing Issues Detected",
    icon: BlockIcon,
    className: "corner-bottom-right-bevel-error"
  }
}

function ProductItem(props: ProductItemProps) {
  const {
    isSelected,
    onSelect,
    onEditItem,
    isEditable = true,
    openDrawer,
    item: {
      id,
      config_formatted_json,
      material_title,
      total_price,
      config,
      material,
      unit_price,
      amount,
      product
    }
  } = props
  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const [imageLoaded, setImageLoaded] = useState(false)

  const isRequiresReview = technologies
    .find((e) => e.materials.find((m) => m.id === material))
    ?.materials.find((m) => m.id === material)
    ?.post_production?.some((n) =>
      config?.post_production?.some(
        (p) => p.uuid === n.uuid && n.note_for_user === "Requires manual review"
      )
    )

  const tech = useMemo(
    () => config_formatted_json?.find((item) => item.key === "Technology"),
    [config_formatted_json]
  )
  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const currentModel = useSelector<RootState, ModelResponse | undefined>(
    (s) => s.quote.currentModels[product?.id || 0]
  )

  const modelAnalysis = useSelector<RootState, IModelAnalysis | undefined>(
    (s) => s.product.modelsAnalysis[product?.id || 0]
  )
  const { currency } = useCurrency()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [deletePurchase, { isLoading: isLoadingDelete }] =
    useDeletePurchaseMutation()
  const [updateProduct, { isLoading }] = useUpdateProductMutation()

  useAppLoading([isLoading, isLoadingDelete])

  const handleDeleteItem = (id: number) => {
    deletePurchase({
      orderId: currentQuote?.id || 0,
      purchaseId: id
    })
  }

  const handleChangeQuanity = (value: number) => {
    if (!currentQuote || !currentQuote.id || !id) return

    const configPayload = {
      layer_thickness: config.layer_thickness.uuid,
      filling: config.filling.uuid,
      color: config.color.uuid,
      lead_time: config.lead_time.uuid
    }
    updateProduct({
      orderId: currentQuote?.id,
      productId: id,
      arg: {
        count: value,
        config: configPayload,
        material_id: material,
        model_id: product?.id
      }
    })
  }

  const stopCollapseEvent = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  const statusTag = useMemo(() => {
    if (isRequiresReview) return EModelAnalysisStatus.WARNING
    return modelAnalysis?.status || EModelAnalysisStatus.ERROR
  }, [modelAnalysis, isRequiresReview])

  return (
    <>
      <div
        className="col"
        onClick={() => {
          if (!isEditable) return
          openDrawer?.(id)
        }}
      >
        <div className={`product-tag`}>
          <p>
            <img
              width={16}
              height={16}
              src={tagStatusClass[statusTag]?.icon}
              alt=""
            />
            <span>
              {isRequiresReview
                ? "Requires Manual Review"
                : tagStatusClass[statusTag]?.text}
            </span>
          </p>

          <div
            className={`corner-bottom-right-bevel ${tagStatusClass[statusTag].className}`}
          ></div>
        </div>
        <div className="container">
          <div className="col-1">
            {/* <CustomCheckbox
              checked={isSelected}
              onChange={(e) => onSelect(e.target.checked)}
              onClick={(e) => stopCollapseEvent(e)}
            /> */}
            <div
              className="product-viewer"
              onClick={(e) => {
                setIsModalOpen(true)
                stopCollapseEvent(e)
              }}
            >
              {currentModel?.thumb_120x120 && (
                <img
                  src={currentModel?.thumb_120x120}
                  alt={currentModel?.title}
                  style={{ display: imageLoaded ? undefined : "none" }}
                  onLoad={() => {
                    setImageLoaded(true)
                  }}
                />
              )}

              {!imageLoaded && <Skeleton width={120} height={120} />}
            </div>

            {currentModel ? (
              <div className="col-2-text">
                <div className="col-2-text-filename">
                  {product?.title || ""}
                </div>
                <div className="col-2-size">
                  {currentModel?.size && (
                    <p>
                      {currentModel?.size?.x.toFixed(2) || 0} x
                      {currentModel?.size.y?.toFixed(2) || 0} x
                      {currentModel?.size.z?.toFixed(2) || 0}
                      {currentModel?.units || ""}
                    </p>
                  )}
                </div>
                <div className="col-2-volume">
                  <p>
                    {currentModel?.volume?.toFixed(2)}{" "}
                    {currentModel?.units ? currentModel?.units + "3" : ""}
                  </p>
                </div>
                {modelAnalysis ? (
                  <div>
                    <Button
                      customSize={Size.EXTRA_SMALL}
                      hierarchy={ButtonHierarchy.TONAL}
                      customType={Type.NEUTRAL}
                      customClassName={[
                        "button",
                        buttonStatusClass[modelAnalysis.status] ||
                          "button-loading"
                      ].join(" ")}
                      onClick={(e) => {
                        setIsModalOpen(true)
                        stopCollapseEvent(e)
                      }}
                    >
                      {modelAnalysis.status === EModelAnalysisStatus.LOADING
                        ? "Performing DFM Analysis..."
                        : "View DFM Analysis"}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="col-2-text">
                <div className="col-2-text-filename">{product?.title}</div>
                <div className="col-2-size">
                  <p>
                    <Skeleton width={120} height={20} />
                  </p>
                </div>
                <div className="col-2-volume">
                  <p>
                    <Skeleton width={60} height={20} />
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="col-3">
            <p>{tech?.value || ""}</p>
            <p>
              {material_title || ""}, {config.color.name_for_user || ""}
            </p>
            <p>{config.layer_thickness.name_for_user || ""} um</p>
            <p>
              {config.filling.name_for_user ||
                config.filling.value?.toFixed(1) + " percent" ||
                ""}
            </p>
            <p>{}</p>
          </div>
          <div className="col-4">
            <p>Visual Inspection</p>
            {config?.post_production?.length > 0 && (
              <>
                {config?.post_production?.map((item, index) => (
                  <div key={`${index} post`}>
                    <p>
                      {item.title} {item.countable && `(${item.quantity})`}
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="col-5" onClick={(e) => e.stopPropagation()}>
            <NumberField value={amount} setValue={handleChangeQuanity} />
          </div>
          <div className="col-6">
            <div className="col-6-text">
              <p>
                {currency}
                {calculatePerPartCost(
                  unit_price,
                  currentQuote?.product_count,
                  Number(currentQuote?.startup_cost),
                  amount
                )}
              </p>
              <p>
                {currency}
                {calculatePerPartCost(
                  unit_price,
                  currentQuote?.product_count,
                  Number(currentQuote?.startup_cost)
                )}{" "}
                / part
              </p>
            </div>
            {isEditable ? (
              <div className="col-6-action">
                <Button
                  hierarchy={ButtonHierarchy.LINK}
                  onClick={() => onEditItem(id)}
                  customSize={Size.MEDIUM}
                  customClassName="edit-btn"
                >
                  <EditOutlined /> Edit Configuration
                </Button>
                <Button
                  hierarchy={ButtonHierarchy.LINK}
                  onClick={(e) => {
                    handleDeleteItem(id)
                    stopCollapseEvent(e)
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            ) : (
              <div className="icon-loading">
                <img src={LoadingIcon} />
              </div>
            )}
          </div>
        </div>
        <div className="date-delivery">
          <p>Ready soon as:</p>
          <p>{calculateDeliveryDate(config.lead_time.days)}</p>
        </div>
      </div>

      <ViewerModal
        modelTitle={product?.title}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        currentPart={currentModel}
        loading={false}
        analysis={modelAnalysis}
        technology={config_formatted_json}
      />
    </>
  )
}

export default ProductItem
